<template>
  <div 
    v-show="showTips"
    ref="repurchase"
    class="repurchase-label"
    :class="{
      'repurchase-label__right': position === 'button',
      'repurchase-label__left': position === 'more',
      'show-clsoe': showClose
    }"
    @click.stop
  >
    <img 
      class="repurchase-label__icon"
      :src="transformImg({ img: repurchaseInfo.icon })" 
    />
    <span 
      v-if="gap"
      :style="styleObject"
      class="repurchase-label__arrow"
    >
    </span>
    <div class="repurchase-label__content">
      <div 
        v-if="repurchaseInfo.tip"
        class="repurchase-label__text"
        v-html="repurchaseInfo.tip"
      >
      </div>
      <div 
        v-if="repurchaseInfo.tip_for_extra"
        class="repurchase-label__text is-normal"
        v-html="repurchaseInfo.tip_for_extra"
      >
      </div>
    </div>

    <Icon 
      v-if="showClose"
      class="repurchase-label__close"
      name="sui_icon_close_16px" 
      size="8px"
      @click.stop="onClose"
    />
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-19-1' })

export default {
  name: 'RepurchaseLabel',
  components: {
    Icon
  },
  props: {
    repurchaseInfo: {
      type: Object,
      default: () => {}
    },
    locals: {
      type: Object,
      default: () => {}
    },
    position: {
      type: String,
      default: 'button'
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      gap: 0,
      showTips: true
    }
  },
  computed: {
    styleObject() {
      const obj = {}
      if (this.position === 'more') {
        obj[this.locals.GB_cssRight ? 'right' : 'left'] = `${this.gap}px` 
      } else {
        obj[this.locals.GB_cssRight ? 'left' : 'right'] = `${this.gap}px` 
      }
      return obj
    },
  },
  mounted() {
    this.getArrowPosition()
  },
  update() {
    this.getArrowPosition()
  },
  methods: {
    transformImg,
    getArrowPosition() {
      const curDom = this.$refs.repurchase
      if (curDom && curDom.parentNode) {
        const parentRect = curDom.parentNode.getBoundingClientRect()
        const curRect = curDom.getBoundingClientRect()
        const width = Math.min(parentRect.width, curRect.width)
        this.gap = Math.round(width / 2 - 4)
      }
    },
    onClose() {
      this.showTips = false
      daEventCenter.triggerNotice({
        daId: '1-19-1-154',
        extraData: {}
      })
    },
  },
}
</script>
<style lang="less" scoped>
.repurchase-label {
  position: absolute;
  top: -16/75rem;
  transform: translateY(-100%);
  display: flex;
  direction: ltr !important;
  padding: 8/75rem;
  border-radius: 1px;
  color: @sui_color_discount;
  background: rgba(255, 238, 238, 0.9);

  &.show-clsoe {
    padding-right: 16/75rem;
  }

  &__right {
    right: 0;
  }

  &__left {
    left: 0;
  }

  &__arrow {
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    z-index: 10;
    background: rgba(255, 238, 238, 0.9);
    bottom: -4px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
  }

  &__icon {
    position: relative;
    width: 20/75rem;
    height: 20/75rem;
    z-index: 11;
  }

  &__content {
    max-width: 5rem;
    width: max-content;
    word-break: break-word;
    white-space: normal;
  }

  &__text {
    position: relative;
    z-index: 11;
    font-size: 8px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-left: 4/75rem;
    text-transform: none;
    .line-camp(2);

    &.is-normal {
      font-weight: normal;
    }
  }
}
</style>
