<template>
  <div class="repurchase-notice">
    <img 
      class="repurchase-notice__icon"
      :src="transformImg({ img: repurchaseInfo.icon })" 
    />
    <div class="repurchase-notice__content">
      <div 
        class="repurchase-notice__text"
        v-html="repurchaseText"
      >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { transformImg } from '@shein/common-function'

const props = defineProps({
  repurchaseInfo: {
    type: Object,
    default: () => {},
  },
})

const repurchaseText = computed(() => {
  let tip = props.repurchaseInfo.tip || ''
  if (props.repurchaseInfo.tip_for_extra) {
    tip = `${tip} ${props.repurchaseInfo.tip_for_extra}`
  }
  return tip
})
</script>

<style lang="less" scoped>
.repurchase-notice {
  position: absolute;
  top: 10/75rem;
  right: -10/75rem;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 4/75rem 8/75rem;
  color: #F96338;
  background: #FFEEEE;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    transform: translateY(100%);
    border: 4/75rem solid transparent;
    border-left-color: #FFEEEE;
    border-top-color: #FFEEEE;
  }

  &__icon {
    width: 20/75rem;
    height: 20/75rem;
  }

  &__content {
    max-width: 428/75rem;
    margin-left: 4/75rem;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    .line-camp(2);
  }

  &__text {
    font-size: 8px;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
